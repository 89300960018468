//tutorial classes
@import 'mixins.scss';
.helper {
  font-size: rem(18) !important;
  color: $black !important;
}

:global(.style_helper__khloY) {
  font-size: 1rem !important;
}
:global(.style_helper__iG1Bm) {
  font-size: 1rem !important;
}
:global(.kVEayH) {
  background: #ed8d47 !important;
}

:global(.fKLrJo) {
  background: #ed8d47 !important;
}

:global(.style_helper__iG1Bm) {
  font-size: 1rem !important;
}


   